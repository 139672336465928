import { Listbox, Transition } from '@headlessui/react';
import classNames from 'classnames';
import React, { Fragment } from 'react';
import { Check, Lock, Unlock } from 'src/assets/icons';
import StatusTag from '../ui/tags/StatusTags';
import { ProjectAccessLevel, PROJECT_ACCESS_LEVELS, readableProjectAccessLevel } from 'src/lib/utils/projectAccess';
import { useWorkspaceContext } from 'src/context/workspace.context';
import ProjectAccessStatusTag, { accessIconMap } from './ProjectAccessStatusTag';

interface ProjectAccessSelectProps {
  onChange: (access: ProjectAccessLevel) => void;
  readOnly: boolean;
  projectId?: string;
  projectAccessLevel: ProjectAccessLevel;
}

const ProjectPublicAccessSelect: React.FC<ProjectAccessSelectProps> = (
  props
) => {
  const { onChange, readOnly = true, projectAccessLevel } = props;
  const { currentWorkspace } = useWorkspaceContext();

  return (
    <Listbox value={projectAccessLevel} onChange={onChange}>
      <div className="relative px-[6px]">
        {readOnly && (
       <ProjectAccessStatusTag projectAccessLevel={projectAccessLevel} worksSpaceName={currentWorkspace?.workspace.name} />
        )}
        {!readOnly && (
          <>
            <Listbox.Button
              className={classNames(`relative cursor-pointer justify-start`)}
            >
            <ProjectAccessStatusTag projectAccessLevel={projectAccessLevel} worksSpaceName={currentWorkspace?.workspace.name} />
            </Listbox.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Listbox.Options className="fixed left-0 md:left-auto h-fit top-32 md:top-auto w-fit md:translate-x-0 translate-x-[8%] -translate-y-[5%] md:translate-y-0 md:absolute bottom-7 md:right-auto md:bottom-auto mt-3 z-10 overflow-auto p-1 drop-group">
              {PROJECT_ACCESS_LEVELS.map((value, index) => (
                  <Listbox.Option
                    key={index}
                    disabled={readOnly}
                    className={classNames(
                      `drop-item flex px-2 items-center w-full cursor-pointer rounded gap-2.5 h-8 no-snippet`,
                      { 'cursor-pointer': !readOnly }
                    )}
                    value={value}
                  >
                    {({ selected }) => (
                      <>
                        {value ? (
                          <Unlock className="w-3 h-3" />
                        ) : (
                          <Lock className="w-3 h-3" />
                        )}
                        <span
                          className={`flex-1 text-body-small medium text-[var(--text-neutral)] truncate`}
                        >
                         {readableProjectAccessLevel(value, currentWorkspace?.workspace.name ?? 'your workspace')}
                        </span>
                        {selected ? (
                          <span className="">
                            <Check />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </>
        )}
      </div>
    </Listbox>
  );
};

export default ProjectPublicAccessSelect;
