import { Navigate, Route, Routes } from 'react-router';
import ProtectedRoute from 'src/components/ProtectedRoute';
import PostTabContainer from 'src/components/projects/posts/PostTabContainer';
import TaskTabContainer from 'src/components/projects/tasks/TaskTabContainer';
import AuthLayout from 'src/layouts/AuthLayout';
import DashboardLayout from 'src/layouts/DashboardLayout';
import SettingsLayout from 'src/layouts/SettingsLayout';
import WorkspaceLayout from 'src/layouts/WorkspaceLayout';
import LoginPage from 'src/pages/auth/LoginPage';
import ComingSoon from './ComingSoon';
import CommentPage from './CommentPage';
import CreateWorkspacePage from './CreateWorkspacePage';
import FeedsPage from './FeedsPage';
import GetStartedPage from './GetStarted';
import HomePage from './HomePage';
import InboxPage from './InboxPage';
import InviteToWorkspacePage from './InviteToWorkspacePage';
import JoinWorkspacePage from './JoinWorkspacePage';
import MobileHome from './MobileHome';
import NewPasswordPage from './NewPasswordPage';
import ProfileSetupPage from './ProfileSetupPage';
import ProjectPage from './ProjectPage';
import WorkspacePage from './WorkspacePage';
import AcceptInvitePage from './auth/AcceptInvitePage';
import AcceptInvitePageByLink from './auth/AcceptInvitePageByLink';
import AuthCallbackPage from './auth/AuthCallbackPage';
import RegisterPage from './auth/RegisterPage';
import ResetPassword from './auth/ResetPassword';
import VerifyOtpPage from './auth/VerifyOtpPage';
import BillingPage from './settings/workspace/Billing';
import General from './settings/workspace/General';
import Members from './settings/workspace/Members';
import Notification from './settings/workspace/Notification';
import Preference from './settings/workspace/Preference';
import ProfilePage from './settings/workspace/Profile';
import ProjectSettingsPage from './settings/workspace/Project';
import Security from './settings/workspace/Security';
import ChatHomePage from './teams/chats/ChatHomePage';

function PageRoutes() {
  return (
    <Routes>
      <Route path="/" element={<ProtectedRoute exemptRoutes={["/project"]}/>}>
        <Route index element={<HomePage />} />
        <Route element={<AuthLayout />}>
          <Route
            path="accept-invite-link"
            element={<AcceptInvitePageByLink />}
          />
          <Route path="join-workspace" element={<JoinWorkspacePage />} />
          <Route path="create-workspace" element={<CreateWorkspacePage />} />
          <Route
            path="invite-to-workspace"
            element={<InviteToWorkspacePage />}
          />
          <Route path="new-password" element={<NewPasswordPage />} />
          <Route path="profile-setup" element={<ProfileSetupPage />} />
        </Route>
        <Route path="workspace/:workspaceSlug" element={<WorkspaceLayout />}>
          <Route element={<DashboardLayout />}>
            <Route index element={<Navigate to="project" replace />} />
            <Route path="get-started" element={<GetStartedPage />} />
            <Route path="project">
              <Route index element={<WorkspacePage />} />
              <Route path="all" element={<MobileHome />} />
              <Route path=":projectId" element={<ProjectPage />}>
                <Route index element={<Navigate to="posts" replace />} />
                <Route path="posts" element={<PostTabContainer />} />
                <Route path="tasks" element={<TaskTabContainer />} />
              </Route>
              <Route
                path=":projectId/posts/:postId"
                element={<CommentPage />}
              />
              <Route path=":userId/chats" element={<ChatHomePage />} />
            </Route>

            <Route path="inbox" element={<InboxPage />} />
            <Route path="chat" element={<ChatHomePage />} />
            <Route path="feeds" element={<FeedsPage />} />
          </Route>
          <Route path="settings" element={<SettingsLayout />}>
            <Route path="general" element={<General />} />
            <Route path="members" element={<Members />} />
            <Route path="profile" element={<ProfilePage />} />
            <Route path="security" element={<Security />} />
            <Route path="billing" element={<BillingPage />} />
            <Route path="projects" element={<ProjectSettingsPage />} />
            <Route path="notifications" element={<Notification />} />
            <Route path="preference" element={<Preference />} />
            <Route path="what's-new" element={<ComingSoon />} />
          </Route>
        </Route>
      </Route>
      <Route path="/auth" element={<AuthLayout />}>
        <Route path="login" element={<LoginPage />} />
        <Route path="sign-up" element={<RegisterPage />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="accept-invite" element={<AcceptInvitePage />} />
        <Route path="callback" element={<AuthCallbackPage />} />
        <Route path=":id/callback" element={<AuthCallbackPage />} />
        <Route path="verify-otp" element={<VerifyOtpPage />} />
      </Route>
    </Routes>
  );
}

export default PageRoutes;
