import { ApolloProvider as BaseApolloProvider } from '@apollo/client';
import React, { useRef, useState } from 'react';
import { useAuth } from '../context/auth.context';
import { createApolloClient } from '../lib/apolloClient';

export const ApolloProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { refreshAuthToken, logout, isGeneralPublic } = useAuth();
  
  // Create a ref to hold the current isGeneralPublic value
  const isGeneralPublicRef = useRef(isGeneralPublic);
  
  // Update the ref whenever isGeneralPublic changes
  if (isGeneralPublicRef.current !== isGeneralPublic) {
    isGeneralPublicRef.current = isGeneralPublic;
  }
  
  // Create a getter function that always returns the current value
  const getIsGeneralPublic = () => isGeneralPublicRef.current;
  
  // Create the client only once
  const [client] = useState(() => 
    createApolloClient(refreshAuthToken, logout, getIsGeneralPublic)
  );

  return <BaseApolloProvider client={client}>{children}</BaseApolloProvider>;
};