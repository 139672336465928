import { jwtDecode, JwtPayload } from 'jwt-decode';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { webStorage } from 'src/lib/storage';

interface AuthTokens {
  accessToken: string;
  refreshToken: string;
  userId: string;
}

interface AuthState {
  accessToken: string | null;
  refreshToken: string | null;
  userId: string | null;
  isAuthenticated: boolean;
  isGeneralPublic: boolean;
}

export interface AuthContextType extends AuthState {
  login: (tokens: AuthTokens) => void;
  logout: () => void;
  refreshAuthToken: () => Promise<void>;
  getDecodedToken: () => JwtPayload | null;
  setIsGeneralPublic: (value: boolean) => void;
}

const TOKEN_REFRESH_THRESHOLD = 60 * 60 * 1000; // 1 hour before expiration
const TOKEN_CHECK_INTERVAL = 60000; // Check every 1 minute
const HTTP_URL = process.env.REACT_APP_API_URL;

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    setAuthState((prev) => ({
      ...prev,
      isGeneralPublic: webStorage.getItem('isGeneralPublic') === 'true',
    }));
  }, []);
  

  const initializeAuthState = (): AuthState => {
    try {
      const accessToken = webStorage.getItem('accessToken');
      const refreshToken = webStorage.getItem('refreshToken');
      const userId = webStorage.getItem('userId');
      const isGeneralPublic = webStorage.getItem('isGeneralPublic') === 'true';

      if (!accessToken || !refreshToken || !userId) {
        throw new Error('Missing auth tokens');
      }

      const decoded = jwtDecode<JwtPayload>(accessToken);
      if (!decoded.exp || decoded.exp * 1000 < Date.now()) {
        throw new Error('Token expired');
      }

      return {
        accessToken,
        refreshToken,
        userId,
        isAuthenticated: true,
        isGeneralPublic,
      };
    } catch (error) {
      webStorage.removeItem('accessToken');
      webStorage.removeItem('refreshToken');
      webStorage.removeItem('userId');
      webStorage.removeItem('isGeneralPublic');

      return {
        accessToken: null,
        refreshToken: null,
        userId: null,
        isAuthenticated: false,
        isGeneralPublic: false,
      };
    }
  };

  const [authState, setAuthState] = useState<AuthState>(initializeAuthState);

  const setIsGeneralPublic = useCallback((value: boolean) => {
    webStorage.setItem('isGeneralPublic', String(value));
    setAuthState((prev) => ({
      ...prev,
      isGeneralPublic: webStorage.getItem('isGeneralPublic') === 'true',
    }));
  }, []);
  
  

  const getDecodedToken = useCallback((): JwtPayload | null => {
    if (!authState.accessToken) return null;
    try {
      return jwtDecode<JwtPayload>(authState.accessToken);
    } catch {
      return null;
    }
  }, [authState.accessToken]);

  const logout = useCallback(() => {
    webStorage.removeItem('accessToken');
    webStorage.removeItem('refreshToken');
    webStorage.removeItem('userId');
    webStorage.removeItem('isGeneralPublic');

    setAuthState((prev) => ({
      accessToken: null,
      refreshToken: null,
      userId: null,
      isAuthenticated: false,
      isGeneralPublic: false // Preserve isGeneralPublic
    }));

    navigate('/auth/login', { replace: true });
  }, [navigate]);

  const login = useCallback(
    (tokens: AuthTokens) => {
      try {
        const decoded = jwtDecode<JwtPayload>(tokens.accessToken);
        if (!decoded.exp) {
          throw new Error('Invalid token structure');
        }
  
        webStorage.setItem('accessToken', tokens.accessToken);
        webStorage.setItem('refreshToken', tokens.refreshToken);
        webStorage.setItem('userId', tokens.userId);
  
        setAuthState((prev) => ({
          accessToken: tokens.accessToken,
          refreshToken: tokens.refreshToken,
          userId: tokens.userId,
          isAuthenticated: true,
          isGeneralPublic: webStorage.getItem('isGeneralPublic') === 'true',
        }));
      } catch (error) {
        console.error('Invalid token received:', error);
        logout();
      }
    },
    [logout]
  );
  

  const refreshAuthToken = useCallback(async () => {
    try {
      if (!authState.refreshToken || authState.isGeneralPublic) {
        throw new Error('No refresh token available or project is  not open to general public');
      }

      const response = await fetch(`${HTTP_URL}/graphql`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Apollo-Require-Preflight': 'true',
        },
        body: JSON.stringify({
          query: `
            mutation RefreshToken($token: String!) {
              refreshToken(token: $token) {
                accessToken
                refreshToken
                userId
              }
            }
          `,
          variables: {
            token: authState.refreshToken,
          },
        }),
      });

      const { data } = await response.json();

      if (!data?.refreshToken?.accessToken) {
        throw new Error('No new tokens received');
      }

      login({
        accessToken: data.refreshToken.accessToken,
        refreshToken: data.refreshToken.refreshToken,
        userId: data.refreshToken.userId,
      });

      return data.refreshToken.accessToken;
    } catch (error) {
      if (localStorage.getItem('isGeneralPublic') =="false") logout();
      throw error;
    }
  }, [authState.refreshToken, authState.isGeneralPublic, login, logout]);

  useEffect(() => {
    if (!authState.accessToken || authState.isGeneralPublic) return;

    const checkTokenExpiration = () => {
      const decoded = getDecodedToken();
      if (!decoded?.exp) {
        logout();
        return;
      }

      const expirationTime = decoded.exp * 1000;
      const timeUntilExpiry = expirationTime - Date.now();

      if (timeUntilExpiry <= 0) {
        refreshAuthToken().catch(() => {
          console.error('Token refresh failed after expiration');
          logout();
        });
      } else if (timeUntilExpiry <= TOKEN_REFRESH_THRESHOLD) {
        refreshAuthToken().catch(() => {
          console.error('Token refresh failed during periodic check');
        });
      }
    };

    checkTokenExpiration();

    const interval = setInterval(checkTokenExpiration, TOKEN_CHECK_INTERVAL);
    return () => clearInterval(interval);
  }, [authState.accessToken, authState.isGeneralPublic, getDecodedToken, logout, refreshAuthToken]);

  const contextValue = {
    ...authState,
    login,
    logout,
    refreshAuthToken,
    getDecodedToken,
    setIsGeneralPublic,
  };

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
