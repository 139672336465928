import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom';
import { useProject } from 'src/api/project/project.service';
import { useAuth } from 'src/context/auth.context';
import { UserProvider } from 'src/context/user.context';
import PageLoader from './ui/Loader/PageLoader';
import { useEffect } from 'react';

function ProtectedRoute({ exemptRoutes = [] }: { exemptRoutes?: string[] }) {
  const { isAuthenticated, setIsGeneralPublic } = useAuth();
  const location = useLocation();
  const { projectId } = useParams();
  const isExempt = exemptRoutes.some((route) => location.pathname.includes(route));
  let isPublicProject = false;

  const { loading, data } = useProject({ 
    variables: { where: { id: { equals: projectId } } },  
    skip: !isExempt || isAuthenticated || !projectId 
  });

  useEffect(() => {
    if (!isAuthenticated && data?.project?.isGeneralPublic) {
      setIsGeneralPublic(true);
    } else {
      setIsGeneralPublic(false);
    }
  }, [isAuthenticated, data, setIsGeneralPublic]);
  
  if (!isAuthenticated && loading) {
    return <PageLoader />;
  }
  
  isPublicProject = data?.project?.isGeneralPublic || false;
  
  if (!isAuthenticated && !isExempt && !isPublicProject) {
    return <Navigate to="/auth/login" state={{ from: location }} replace />;
  }
  
  return (
    <UserProvider>
      <Outlet context={{ from: "protected", isPublicProject }} />
    </UserProvider>
  );
}

export default ProtectedRoute;