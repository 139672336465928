import { gql } from '@apollo/client';
import {
  INBOX_FIELDS,
  USER_DETAIL_FIELDS,
  WORKSPACE_FIELDS,
} from './fragments';

export const ME = gql`
  query Me {
    me {
      id
      email
      detail {
        ...UserDetailFields
        resettingPassword
      }
      actionStatus {
        hasCreatedPost
        hasCreatedProject
        hasInvitedTeamMembers
        hasVerifiedDomain
        userId
      }
      notificationSettings {
        addedToProject
        deactivatedWorkspace
        deletedProject
        deletedProjectPermanently
        enableEmailNotification
        enablePushNotification
        newCommentInMentionedPost
        newPostCommentReplies
        newPostOrCommentMention
        newProjectPost
        updatedProjectStatus
      }
      suggestedWorkspaces {
        id
        name
        logo {
          url
        }
        members {
          id
          userId
        }
        slug
        invitations {
          id
          inviteeEmail
        }
      }
      userWorkspaces {
        id
        isOwner
        workspace {
          id
          logo {
            url
          }
          name
          projects {
            id
            name
          }
          members {
            id
            userId
          }
          slug
        }
      }
      invitations {
        workspaceId
        accepted
      }
    }
  }
  ${USER_DETAIL_FIELDS}
`;

export const POSITION_ENUM = gql`
  query PositionEnum {
    __type(name: "Position") {
      name
      kind
      enumValues {
        name
        description
      }
    }
  }
`;

export const WORKSPACES = gql`
  query Workspaces {
    workspaces {
      id
      name
      slug
      projects {
        id
        name
      }
      #            members {
      #                id
      #                userId
      #            }
    }
  }
`;

export const WORKSPACE = gql`
  query Workspace($where: WorkspaceWhereUniqueInput!) {
    workspace(where: $where) {
      ...WorkspaceFields
    }
  }
  ${WORKSPACE_FIELDS}
`;

export const WORKSPACE_PLANS = gql`
  query WorkspacePlans($where: WorkspacePlanWhereInput) {
    workspacePlans(where: $where) {
      createdAt
      currency
      id
      isActive
      billingCycle
      maxMembers
      maxPosts
      maxProjects
      name
      pricePerUser
      stripePriceId
      updatedAt
    }
  }
`;

export const WORKSPACE_PLAN = gql`
  query WorkspacePlan($where: WorkspacePlanWhereInput) {
    workspacePlan(where: $where) {
      createdAt
      currency
      id
      isActive
      billingCycle
      maxMembers
      maxPosts
      maxProjects
      name
      pricePerUser
      stripePriceId
      updatedAt
    }
  }
`;

export const USER_WORKSPACE = gql`
  query UserWorkspace($where: UserWorkspaceWhereUniqueInput!) {
    userWorkspace(where: $where) {
      id
      user {
        detail {
          ...UserDetailFields
        }
      }
      workspace {
        ...WorkspaceFields
      }
    }
  }
  ${USER_DETAIL_FIELDS}
  ${WORKSPACE_FIELDS}
`;

export const WORKSPACE_INVITATION = gql`
  query WorkspaceInvitation($where: WorkspaceInvitationWhereUniqueInput!) {
    workspaceInvitation(where: $where) {
      accepted
      inviteeEmail
      inviter {
        detail {
          ...UserDetailFields
        }
      }
      workspace {
        name
        logo {
          filename
          mimetype
          url
        }
      }
    }
  }
  ${USER_DETAIL_FIELDS}
`;

export const WORKSPACE_BY_INVITATION = gql`
  query GetWorkspaceByInviteToken($inviteToken: String!) {
    workspaceByInviteToken(inviteToken: $inviteToken) {
      id
      name
      slug
      logo {
        filename
        mimetype
        url
      }
    }
  }
`;

export const PROJECT_TOPICS = gql`
  query ProjectTopics($where: ProjectTopicWhereInput) {
    projectTopics(where: $where) {
      id
      name
    }
  }
`;

export const PROJECT_GOALS = gql`
  query ProjectGoals($where: ProjectGoalWhereInput) {
    projectGoals(where: $where) {
      id
      name
    }
  }
`;

export const PROJECTS = gql`
  query Projects($where: ProjectWhereInput!) {
    projects(where: $where) {
      id
      name
      description
      owner {
        id
        detail {
          ...UserDetailFields
        }
      }
      createdAt
      archivedAt
      deleteAt
      collaboratorIDs
      collaborators {
        id
        detail {
          ...UserDetailFields
        }
      }
      goalIDs
      status
      isPublic
      workspace {
        id
        slug
        topics {
          id
          name
        }
      }
      links {
        label
        url
        id
      }
    }
  }
  ${USER_DETAIL_FIELDS}
`;

export const PROJECT = gql`
  query Project($where: ProjectWhereInput!) {
    project(where: $where) {
      id
      name
      description
      owner {
        id
        detail {
          ...UserDetailFields
        }
      }
      createdAt
      collaboratorIDs
      collaborators {
        id
        detail {
          ...UserDetailFields
        }
      }
      goalIDs
      status
      isPublic
      isGeneralPublic
      workspace {
        id
        slug
        topics {
          id
          name
        }
      }
      links {
        label
        url
        id
      }
    }
  }
  ${USER_DETAIL_FIELDS}
`;

export const ARCHIVED_PROJECTS = gql`
  query ArchivedProjects($where: ProjectWhereInput!) {
    archivedProjects(where: $where) {
      id
      name
      description
      owner {
        id
        detail {
          ...UserDetailFields
        }
      }
      createdAt
      archivedAt
      deleteAt
      collaboratorIDs
      goalIDs
      status
      isPublic
      workspace {
        id
        slug
        topics {
          id
          name
        }
      }
    }
  }
  ${USER_DETAIL_FIELDS}
`;

export const POSTS = gql`
  query Posts(
    $orderBy: [PostOrderByWithRelationInput!]
    $where: PostWhereInput
  ) {
    posts(orderBy: $orderBy, where: $where) {
      id
      title
      comment
      type
      topic {
        id
        name
      }
      topicId
      createdAt
      attachments {
        filename
        mimetype
        url
      }
      author {
        id
        detail {
          ...UserDetailFields
        }
      }
      comments {
        id
        author {
          id
          detail {
            ...UserDetailFields
          }
        }
        comment
        createdAt
        mentionedUsers {
          id
          detail {
            fullName
          }
        }
        mentionedUserIDs
        responses {
          id
          author {
            id
            detail {
              ...UserDetailFields
            }
          }
          comment
          createdAt
          post {
            id
            title
            author {
              id
              detail {
                ...UserDetailFields
              }
            }
            project {
              id
              workspace {
                slug
              }
            }
          }
          mentionedUsers {
            id
            detail {
              fullName
            }
          }
          mentionedUserIDs
        }
        post {
          id
          title
          author {
            detail {
              ...UserDetailFields
            }
          }
          project {
            id
            workspace {
              slug
            }
          }
        }
      }
      mentionedUserIDs
      mentionedUsers {
        id
        detail {
          fullName
        }
      }
      project {
        id
        workspace {
          slug
        }
      }
    }
  }
  ${USER_DETAIL_FIELDS}
`;

export const TASKS = gql`
  query Tasks(
    $orderBy: [TaskOrderByWithRelationInput!]
    $where: TaskWhereInput
  ) {
    tasks(orderBy: $orderBy, where: $where) {
      id
      title
      description
      createdAt
      createdBy {
        id
        detail {
          ...UserDetailFields
        }
      }
      createdById
      workStream {
        id
        name
      }
      workStreamIDs
      status
      priority
      dueDate
      assignee {
        id
        detail {
          ...UserDetailFields
        }
      }
      assigneeId
      comments {
        id
        author {
          id
          detail {
            ...UserDetailFields
          }
        }
        comment
        createdAt
        mentionedUsers {
          id
          detail {
            fullName
          }
        }
        mentionedUserIDs
        responses {
          id
          author {
            id
            detail {
              ...UserDetailFields
            }
          }
          comment
          createdAt
          task {
            id
            title
            createdBy {
              id
              detail {
                ...UserDetailFields
              }
            }
            project {
              id
              workspace {
                slug
              }
            }
          }
          mentionedUsers {
            id
            detail {
              fullName
            }
          }
          mentionedUserIDs
        }
        task {
          id
          title
          createdBy {
            id
            detail {
              ...UserDetailFields
            }
          }
          project {
            id
            workspace {
              slug
            }
          }
        }
      }
      project {
        id
        workspace {
          slug
        }
      }
    }
  }
  ${USER_DETAIL_FIELDS}
`;

export const INBOX = gql`
  query Inbox(
    $orderBy: [InboxOrderByWithRelationInput!]
    $where: InboxWhereInput!
  ) {
    inbox(orderBy: $orderBy, where: $where) {
      ...InboxFields
    }
  }
  ${INBOX_FIELDS}
`;

export const INBOX_COUNT = gql`
  query InboxCount($workspaceId: String!) {
    inboxCount(workspaceId: $workspaceId)
  }
`;
