import React, { ReactNode } from "react";
import StatusTag from "src/components/ui/tags/StatusTags";
import { ProjectAccessLevel, readableProjectAccessLevel } from 'src/lib/utils/projectAccess';
import { Lock, Link, Users } from 'src/assets/icons';

export const accessIconMap: { [k in ProjectAccessLevel]: ReactNode } = {
    'ANYONE_WITH_LINK': <Link className="w-3 h-3" />,
    'INVITE_ONLY': <Lock className="w-3 h-3" />,
    'WORKSPACE_ONLY': <Users className="w-3 h-3" />
}

interface ProjectAccessStatusTagProps {
    projectAccessLevel: ProjectAccessLevel;
    worksSpaceName?: string;
}

const ProjectAccessStatusTag = ({ projectAccessLevel, worksSpaceName = 'your workspace' }: ProjectAccessStatusTagProps) => {
    return <StatusTag
        value={readableProjectAccessLevel(projectAccessLevel, worksSpaceName)}
        color={'default'}
        size={'medium'}
        leftIcon={accessIconMap[projectAccessLevel]}
    />
}

export default ProjectAccessStatusTag;