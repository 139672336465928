import { gql } from '@apollo/client';

export const USER_DETAIL_FIELDS = gql`
  fragment UserDetailFields on UserDetail {
    id
    fullName
    position
    avatar {
      filename
      mimetype
      url
    }
  }
`;

export const WORKSPACE_FIELDS = gql`
  fragment WorkspaceFields on Workspace {
    id
    logo {
      filename
      mimetype
      url
    }
    name
    slug
    website
    enableInviteToken
    enableVerifiedDomains
    billings {
      id
      amountPaid
      createdAt
      currency
      endDate
      plan {
        id
        name
        pricePerUser
        currency
        isActive
        createdAt
        billingCycle
      }
      stripeInvoiceId
      subscription
      status
    }
    plan {
      id
      name
      pricePerUser
      currency
      isActive
      createdAt
    }
    planId
    isSubscriptionPlanActive
    projects {
      id
      name
      isPublic
      collaboratorIDs
      ownerId
      collaborators {
        id
        detail {
          ...UserDetailFields
        }
      }
    }
    topics {
      id
      name
    }
    members {
      id
      isOwner
      role
      deactivatedAt
      user {
        id
        role
        status
        email
        detail {
          id
          avatar {
            filename
            mimetype
            url
          }
          fullName
          position
        }
      }
    }
    invitations {
      id
      inviteeEmail
      role
      accepted
    }
    inviteToken
    domains {
      id
      domain
      enabled
      createdAt
      verifiedAt
      verificationEmail
    }
  }
  ${USER_DETAIL_FIELDS}
`;

export const WORKSPACE_PLAN_FIELDS = gql`
  fragment WorkspacePlanFields on WorkspacePlan {
    id
    amountPaid
    createdAt
    currency
    endDate
    plan {
      id
      name
      price
      currency
      isActive
      createdAt
      deletedAt
    }
    planId
    startDate
    status
    stripeInvoiceId
    workspace {
      id
      name
    }
  }
`;

export const INBOX_FIELDS = gql`
  fragment InboxFields on Inbox {
    id
    category
    createdAt
    isRead
    sender {
      id
      detail {
        ...UserDetailFields
      }
    }
    type
    context
  }
  ${USER_DETAIL_FIELDS}
`;
