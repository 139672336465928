import { useLocalStorage } from '@uidotdev/usehooks';
import { Files, Lock, Status, UserCircle, Users } from 'src/assets/icons';
import ProjectCollaboratorsSelect from 'src/components/projects/ProjectCollaboratorsSelect';
import ProjectLinksSelect from 'src/components/projects/ProjectLinksSelect';
import ProjectPublicAccessSelect from 'src/components/projects/ProjectPublicAccessSelect';
import ProjectStatusSelect from 'src/components/projects/ProjectStatusSelect';
import { IProjectLink, ProjectStatus } from 'src/lib/types';
import AssignItem from '../ui/AssignItem';
import ProjectLeadSelect from './ProjectLeadSelect';

import { IProject } from 'src/types';
import { ProjectAccessLevel } from 'src/lib/utils/projectAccess';
import { type FormData } from './EditProjectModal';
import { checkProjectAccess } from 'src/lib/utils/projectAccess';
import { HandleInputChange } from './ProjectModal';

type EditProjectDetailsValue = Omit<FormData, 'description'>;

function EditProjectDetails({
  projectId,
  onChange,
  value,
  workspaceSlug,
  readOnly = true,
}: {
  projectId?: string;
  workspaceSlug: string;
  value: EditProjectDetailsValue;
  onChange: HandleInputChange;
  readOnly?: boolean;
}) {
  const [userId] = useLocalStorage('userId', undefined);

  function changeProjectAccess(accessLevel: ProjectAccessLevel) {
    if (accessLevel === 'ANYONE_WITH_LINK') {
      onChange({ isGeneralPublic: true, isPublic: true })
    } else if (accessLevel === 'WORKSPACE_ONLY') {
      onChange({ 'isGeneralPublic': false, isPublic: true });
    } else {
      onChange({ isGeneralPublic: false, isPublic: false })
    }
  }

  return (
    <table className="-my-3 w-full font-medium text-sm">
      <tbody>
        {/* Project lead */}
        <tr>
          <td className="w-[120px] md:w-[133px] py-3">
            <AssignItem icon={<UserCircle />} text="Project Lead" />
          </td>
          <td className="py-3">
            {/* <ViewProjectLead 
              user={user}
            /> */}
            <ProjectLeadSelect
              userId={userId}
              projectId={projectId}
              workspaceSlug={workspaceSlug}
              value={value.owner}
              onChange={(value) => onChange('owner', value)}
              readOnly={readOnly}
            />
          </td>
        </tr>

        {/* Collaborators */}
        <tr>
          <td className="w-[133px] py-3">
            <AssignItem icon={<Users />} text="Collaborators" />
          </td>
          <td className="py-3">
            <ProjectCollaboratorsSelect
              userId={userId}
              projectId={projectId}
              workspaceSlug={workspaceSlug}
              value={value.collaboratorIDs}
              onChange={(value: ProjectAccessLevel) => changeProjectAccess(value)}
              readOnly={readOnly}
            />
          </td>
        </tr>

        {/* Status */}
        <tr>
          <td className="py-3">
            <AssignItem icon={<Status />} text="Status" />
          </td>
          <td className="py-3">
            <ProjectStatusSelect
              value={value.status}
              onChange={(value) => onChange('status', value)}
              readOnly={readOnly}
            />
          </td>
        </tr>

        {/* Access */}
        <tr>
          <td className="py-3">
            <AssignItem icon={<Lock />} text="Access" />
          </td>
          <td className="py-3">
            <ProjectPublicAccessSelect
              projectId={projectId}
              // checked={value.isPublic}
              projectAccessLevel={checkProjectAccess(value as unknown as IProject)}
              onChange={(value) => changeProjectAccess(value)}
              readOnly={readOnly}
            />
          </td>
        </tr>

        {/* Resources */}
        <tr className="">
          <td className="py-3 flex">
            <AssignItem icon={<Files />} text="Resources" />
          </td>
          <td className="py-3">
            <ProjectLinksSelect
              projectId={projectId}
              value={value.links}
              onChange={(value) => onChange('links', value)}
              readOnly={readOnly}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default EditProjectDetails;
