import {
  ApolloClient,
  ApolloLink,
  fromPromise,
  InMemoryCache,
  split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { createClient } from 'graphql-ws';
import { webStorage } from 'src/lib/storage';

const HTTP_URL = process.env.REACT_APP_API_URL;
const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL;

export const createApolloClient = (
  refreshAuthToken: () => Promise<void>,
  logout: () => void,
  isGeneralPublicGetter: () => boolean
) => {
  const authLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      'Apollo-Require-Preflight': 'true',
      authorization: webStorage.getItem('accessToken')
        ? `Bearer ${webStorage.getItem('accessToken')}`
        : '',
    },
  }));

  const httpLink = createUploadLink({
    uri: HTTP_URL + '/graphql',
    headers: {
      'Apollo-Require-Preflight': 'true',
    },
  });

  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        for (const err of graphQLErrors) {
          const statusCode = (
            err.extensions?.originalError as { statusCode?: number }
          )?.statusCode;
          
        
          const isGeneralPublic = isGeneralPublicGetter();
       
          if (statusCode === 401 && !isGeneralPublic) {
            return fromPromise(
              refreshAuthToken()
                .then(() => {
                  const newToken = webStorage.getItem('accessToken');
                  if (!newToken) throw new Error('No token after refresh');

                  operation.setContext({
                    headers: {
                      ...operation.getContext().headers,
                      authorization: `Bearer ${newToken}`,
                    },
                  });
                  return forward(operation);
                })
                .catch(() => {
                  logout();
                  return forward(operation);
                })
            ).flatMap((observable) => observable);
          }

          if (statusCode === 403) {
            throw new Error("You don't have the required permission");
          }
        }
      }

      if (
        networkError &&
        'statusCode' in networkError &&
        networkError.statusCode === 401
      ) {
        logout();
      }
    }
  );

  const wsLink = new GraphQLWsLink(
    createClient({
      url: WEBSOCKET_URL as string,
      connectionParams: () => ({
        authorization: webStorage.getItem('accessToken')
          ? `Bearer ${webStorage.getItem('accessToken')}`
          : '',
      }),
    })
  );

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    ApolloLink.from([authLink, errorLink, httpLink] as ApolloLink[])
  );

  return new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            me: {
              merge: true,
            },
            projects: {
              merge: true,
            },
            workspace: {
              merge: true,
            },
            userWorkspace: {
              merge: true,
            },
          },
        },
      },
    }),
    link: splitLink,
    connectToDevTools: process.env.NODE_ENV === 'development',
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
      },
      query: {
        fetchPolicy: 'cache-first',
      },
      mutate: {
        errorPolicy: 'all',
      },
    },
  });
};