import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { useMe } from 'src/api/auth/auth.service';
import { useUpdateUserActionStatus } from 'src/api/user/user.service';
import ToastMessage from 'src/components/ToastMessage';
import PageLoader from 'src/components/ui/Loader/PageLoader';
import { IUser } from 'src/types/entities';
import { useAuth } from 'src/context/auth.context';

interface UserContextType {
  user: IUser | null;
  loading: boolean;
  refetchUserData: () => Promise<void>;
  handleSkipUserAction: () => Promise<void>;
  isSkippingUserAction: boolean;
  userSetupComplete: boolean;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { workspaceSlug } = useParams<{
    workspaceSlug: string;
  }>();
  const navigate = useNavigate();
  const { isAuthenticated, isGeneralPublic } = useAuth();

  const {
    data: userData,
    loading: isLoadingUser,
    refetch: refetchUserData,
  } = useMe({
    skip: !isAuthenticated || isGeneralPublic,
    onError: (error: Error) => {
     
      if (!(isGeneralPublic && error.message.includes("401"))) {
        toast((t) => (
          <ToastMessage id={t.id} title={`Error: ${error.message}`} />
        ));
      }
    },
  });

  const [updateUserActionStatus, { loading: isSkippingUserAction }] =
    useUpdateUserActionStatus();

  const handleSkipUserAction = useCallback(async () => {
    try {

      if (!isAuthenticated) {
        return;
      }
      
      const { data } = await updateUserActionStatus();

      if (data.updateUserActionStatus) {
        navigate(`/workspace/${workspaceSlug}/project`);
        await refetchUserData();
      }
    } catch (error) {
      toast((t) => <ToastMessage id={t.id} title={(error as Error).message} />);
    }
  }, [updateUserActionStatus, refetchUserData, navigate, workspaceSlug, isAuthenticated]);

  const userSetupComplete = useMemo(() => {
   
    if (isGeneralPublic && !isAuthenticated) {
      return true;
    }
    
    const actionStatus = userData?.me?.actionStatus;
    return actionStatus
      ? actionStatus.hasCreatedPost &&
          actionStatus.hasCreatedProject &&
          actionStatus.hasInvitedTeamMembers &&
          actionStatus.hasVerifiedDomain
      : false;
  }, [userData?.me?.actionStatus, isGeneralPublic, isAuthenticated]);

  const contextValue = useMemo<UserContextType>(
    () => ({
      user: userData?.me || null,
      loading: isLoadingUser && isAuthenticated && !isGeneralPublic,
      refetchUserData: async () => {
        try {
       
          if (isAuthenticated && !isGeneralPublic) {
            await refetchUserData();
          }
        } catch (error) {
          if (!(isGeneralPublic && (error as Error).message.includes("401"))) {
            toast((t) => (
              <ToastMessage id={t.id} title={(error as Error).message} />
            ));
          }
        }
      },
      handleSkipUserAction,
      isSkippingUserAction,
      userSetupComplete,
    }),
    [
      userData?.me,
      isLoadingUser,
      refetchUserData,
      handleSkipUserAction,
      isSkippingUserAction,
      userSetupComplete,
      isAuthenticated,
      isGeneralPublic,
    ]
  );


  if (isLoadingUser && isAuthenticated && !isGeneralPublic && !userData) {
    return <PageLoader />;
  }

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
