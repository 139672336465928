import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useLocalStorage } from '@uidotdev/usehooks';
import BalloonEditor from 'ckeditor5-custom-build';
import { AnimatePresence, motion } from 'framer-motion';
import { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useCreateOneProject,
  useUpdateOneProject,
} from 'src/api/project/project.service';
import { Close } from 'src/assets/icons';
import EditProjectDetails from 'src/components/projects/EditProjectDetails';
import ValidationErrorMessages from 'src/components/ValidationErrorMessages';
import usePrevious from 'src/hooks/usePrevious';
import { deepEqual } from 'src/lib/helpers';
import { IProject, IProjectLink, ProjectStatus } from 'src/lib/types';
import Validator from 'validatorjs';
import ToastMessage from '../ToastMessage';
import Button from '../ui/Button/Button';
import IconButton from '../ui/Button/IconButton';
import HeaderText from '../ui/HeaderTexts';
import HorizontalDivider from '../ui/HorizontalDivider';

type FormDataFields = 'name' | 'description' | 'collaboratorIDs' | 'topicIDs' | 'status' | 'isPublic' | 'isGeneralPublic' | 'links';
export type FormData = Pick<IProject, FormDataFields> & {
  owner: IProject['owner']['id'];
};

export type HandleInputChange = {
  (field: keyof FormData, value: any): void;
  (updates: Partial<FormData>): void;
};

export default function ProjectModal({
  show,
  handleClose,
  project,
}: {
  show: boolean;
  handleClose: Function;
  project?: IProject;
}) {
  const navigate = useNavigate();
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const [userId] = useLocalStorage('userId', undefined);
  const [formData, setFormData] = useState<FormData>({
    name: project?.name ?? '',
    description: project?.description ?? '',
    owner: project?.owner.id ?? userId ?? '',
    collaboratorIDs: project?.collaboratorIDs ?? [],
    topicIDs: project?.topicIDs ?? [],
    status: project?.status ?? ProjectStatus.PLANNING,
    isPublic: project?.isPublic ?? true,
    isGeneralPublic: project?.isGeneralPublic ?? false,
    links:
      project?.links.map((link) => ({
        url: link.url,
        label: link.label,
        id: link.id,
      })) ?? [],
  });

  const prevProject = usePrevious(project);
  const [formErrors, setFormErrors] =
    useState<Validator.ValidationErrors | null>(null);
  const [createOneProject, { loading: createOneProjectLoading }] =
    useCreateOneProject();
  const [updateOneProject, { loading: updateOneProjectLoading }] =
    useUpdateOneProject();
  const loading = createOneProjectLoading || updateOneProjectLoading;

  useEffect(() => {
    if (prevProject && project && !deepEqual(prevProject, project)) {
      setFormData({
        name: project.name,
        description: project.description,
        owner: project.owner.id,
        collaboratorIDs: project.collaboratorIDs,
        topicIDs: project.topicIDs,
        status: project.status,
        isPublic: project.isPublic,
        isGeneralPublic: project?.isGeneralPublic,
        links: project?.links.map((link) => ({
          url: link.url,
          label: link.label,
          id: link.id,
        })),
      });
    }
  }, [prevProject, project]);

  const handleInputChange: HandleInputChange = (fieldOrUpdates: string | Partial<typeof formData>, value?: any) => {
    if (typeof fieldOrUpdates === 'string') {
      setFormData((prev) => ({ ...prev, [fieldOrUpdates]: value }));
    } else {
      setFormData((prev) => ({ ...prev, ...fieldOrUpdates }));
    }
    setFormErrors(null);
  };

  const handleSubmit = () => {
    const validation = new Validator(formData, {
      name: 'required',
      description: 'required',
    });

    if (validation.passes()) {
      setFormErrors(null);

      if (project) {
        const originalLinkIds = new Set(project.links.map((link) => link.id));

        const existingLinks = formData.links.filter((link) =>
          originalLinkIds.has(link.id)
        );
        const newLinks = formData.links.filter(
          (link) => !originalLinkIds.has(link.id)
        );

        const linkIdsToDelete = project.links
          .filter(
            (link) =>
              !formData.links.some((formLink) => formLink.id === link.id)
          )
          .map((link) => link.id);

        updateOneProject({
          variables: {
            data: {
              owner: {
                connect: { id: formData.owner },
              },
              collaborators: {
                set: formData.collaboratorIDs.map((id) => ({ id })),
              },
              topics: {
                set: formData?.topicIDs?.map((id) => ({ id })),
              },
              description: { set: formData.description },
              isPublic: { set: formData.isPublic },
              isGeneralPublic: { set: formData.isGeneralPublic },
              name: { set: formData.name },
              status: { set: formData.status },
              links: {
                updateMany: existingLinks.map((link) => ({
                  where: { id: { equals: link.id } },
                  data: {
                    label: { set: link.label },
                    url: { set: link.url },
                  },
                })),
                create: newLinks.map((link) => ({
                  label: link.label,
                  url: link.url,
                })),
                deleteMany: { id: { in: linkIdsToDelete } },
              },
            },
            where: { id: project.id },
          },
          onCompleted: (data) => {
            handleClose();
          },
          onError: (error) => {
            toast((t) => <ToastMessage id={t.id} title={error.message} />);
          },
        });
      } else {
        createOneProject({
          variables: {
            data: {
              owner: { connect: { id: formData.owner } },
              collaborators: {
                connect: formData.collaboratorIDs.map((id) => ({ id })),
              },
              topics: { connect: formData.topicIDs.map((id) => ({ id })) },
              description: formData.description,
              isPublic: formData.isPublic,
              isGeneralPublic:  formData.isGeneralPublic,
              name: formData.name,
              status: formData.status,
              workspace: { connect: { slug: workspaceSlug } },
              links: formData.links.length
                ? {
                  createMany: {
                    data: [
                      ...formData.links.map((link) => ({
                        url: link.url,
                        label: link.label,
                      })),
                    ],
                  },
                }
                : undefined,
            },
          },
          onCompleted: ({ createOneProject }) => {
            navigate(
              `/workspace/${workspaceSlug}/project/${createOneProject.id}`
            );
            handleClose();
            setFormData({
              name: '',
              description: '',
              owner: userId ?? '',
              collaboratorIDs: [],
              topicIDs: [],
              status: ProjectStatus.PLANNING,
              isPublic: true,
              isGeneralPublic: false,
              links: [],
            });
          },
          onError: (error) => {
            toast((t) => <ToastMessage id={t.id} title={error.message} />);
          },
        });
      }
    } else {
      setFormErrors(validation.errors.all());
    }
  };

  const title = project ? 'Edit Project' : 'Create a project';
  const button = project ? 'Save' : 'Create project';

  return (
    <Fragment>
      <AnimatePresence>
        {show && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed z-20 inset-0 bg-black/30"
          >
            <div className="fixed inset-0 flex w-screen items-start justify-center p-4 overflow-auto">
              <motion.div
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.95 }}
                className="group-container border mt-8 md:mt-16 rounded-xl border-[var(--border-on-bg)] w-full bg-[var(--backgrounds-dropdowns)] max-w-[528px] shadow-[0px_20px_25px_0px_rgba(42,_42,_42,_0.20)]"
              >
                <div className="flex items-center justify-between w-full p-5">
                  <HeaderText subTitle={title} />
                  <IconButton
                    icon={<Close />}
                    size={'micro'}
                    type={'ghost'}
                    onClick={() => handleClose()}
                  />
                </div>
                <HorizontalDivider />
                <div className="p-5 flex flex-col gap-8">
                  <div className="">
                    <input
                      className="w-full input-clear text-heading-4 medium placeholder:text-[var(--text-disabled)] placeholder:text-heading-4 text-[var(--text-default)]"
                      placeholder="Project name"
                      disabled={loading}
                      value={formData.name}
                      autoFocus
                      onChange={(e) =>
                        handleInputChange('name', e.target.value)
                      }
                    />
                    <ValidationErrorMessages name="name" errors={formErrors} />

                    <div className="CKEditor">
                      <CKEditor
                        disabled={loading}
                        editor={BalloonEditor as any}
                        data={formData.description}
                        onChange={(event, editor) =>
                          handleInputChange('description', editor?.getData())
                        }
                        config={{ placeholder: 'Description' }}
                      />
                      <ValidationErrorMessages
                        name="description"
                        errors={formErrors}
                      />
                    </div>
                  </div>
                  {workspaceSlug && (
                    <EditProjectDetails
                      workspaceSlug={workspaceSlug}
                      value={formData}
                      onChange={handleInputChange}
                      readOnly={false}
                    />
                  )}
                </div>
                <HorizontalDivider />
                <div className="p-5">
                  <div className="flex justify-end items-center">
                    <div className="items-center flex gap-3 justify-end">
                      <Button
                        type="button"
                        onClick={() => handleClose()}
                        disabled={loading}
                        value={'Cancel'}
                        size={'medium'}
                        btnType={'neutral'}
                        className="w-auto"
                      />
                      <Button
                        type="button"
                        onClick={handleSubmit}
                        loading={loading}
                        disabled={loading}
                        value={button}
                        size={'medium'}
                        btnType={'primary'}
                        className="w-auto"
                      />
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </Fragment>
  );
}
