import { useLocalStorage } from '@uidotdev/usehooks';
import { AnimatePresence, motion } from 'framer-motion';
import parse from 'html-react-parser';
import { Fragment, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { usePosts, useSummarizePost } from 'src/api/post/post.service';
import { useProject } from 'src/api/project/project.service';
import {
  ArrowBack,
  Close,
  MegaPhone,
  // ThumbsDown,
  // ThumbsUp,
  WorkStream,
} from 'src/assets/icons';
import { ReactComponent as AiLogo } from 'src/assets/svgs/ImgAISummary.svg';
import SectionWrap from 'src/components/common/SectionWrap';
import CommentInputField from 'src/components/projects/comments/CommentInputField';
import PostCommentItem from 'src/components/projects/comments/posts/PostCommentItem';
import PostComments from 'src/components/projects/comments/posts/PostComments';
import DeletePostModal from 'src/components/projects/posts/DeletePostModal';
import PostMenu from 'src/components/projects/posts/PostMenu';
import PostModal from 'src/components/projects/posts/PostModal';
import ToastMessage from 'src/components/ToastMessage';
import Avatar from 'src/components/ui/Avatar/Avatar';
import IconButton from 'src/components/ui/Button/IconButton';
import HeaderText from 'src/components/ui/HeaderTexts';
import HorizontalDivider from 'src/components/ui/HorizontalDivider';
import ImageViewer from 'src/components/ui/ImageViewer';
import PageLoader from 'src/components/ui/Loader/PageLoader';
import ColorTag from 'src/components/ui/tags/ColorTags';
import StatusTag from 'src/components/ui/tags/StatusTags';
import Timestamp from 'src/components/ui/TimeStamp';
import { useUser } from 'src/context/user.context';
import { useComment } from 'src/hooks/project/post/useCommentAction';
import {
  renderLineBreaks,
  transformToTitleCase,
  userExistAsCollaborator,
} from 'src/lib/helpers';
import { cn } from 'src/lib/utils';
import { IPost, IUser } from 'src/types';

interface IState {
  editPost?: {
    show: boolean;
    post: IPost | undefined;
  };
  deletePost?: {
    show: boolean;
    post: IPost | undefined;
  };
}

export default function CommentPage() {
  const navigate = useNavigate();

  const { workspaceSlug, projectId, postId } = useParams<{
    projectId: string;
    postId: string;
    workspaceSlug: string;
  }>();

  const { user } = useUser();
  const [userId] = useLocalStorage('userId', undefined);

  const ROOT_URL = `/workspace/${workspaceSlug}/project`;

  const { loading, data } = usePosts({
    variables: {
      where: {
        id: { equals: postId },
        projectId:{equals: projectId}
      },
      orderBy: { createdAt: 'desc' },
    },
    onError: (error: any) => {
      toast((t) => (
        <ToastMessage
          id={t.id}
          title={`Post not found. Redirecting to project page...`}
        />
      ));
      navigate(`${ROOT_URL}/${projectId}`);
    },
  });

  const { loading: loadingProject, data: projectData } = useProject({
    variables: {
      where: {
        id: { equals: projectId },
        workspace: {
          slug: { equals: workspaceSlug },
        },
      },
    },
  });

  const [state, setState] = useState<IState>({
    editPost: {
      show: false,
      post: undefined,
    },
    deletePost: undefined,
  });
  const [summarizePostData, setSummarizePostData] = useState<any>(null);

  const post = data?.posts[0];
  const isPostOwner = userId === post?.author?.id;

  const handleCopyPost = (post: IPost) => () => {
    const origin = window.location.origin;
    const project = post.project;
    const workspace = project.workspace;
    const link = `${origin}/workspace/${workspace.slug}/project/${project.id}/post/${post.id}`;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        toast((t) => (
          <ToastMessage
            id={t.id}
            title={'Link to post is copied to clipboard'}
          />
        ));
      })
      .catch((err) => {
        toast((t) => (
          <ToastMessage id={t.id} title={'Error copying text to clipboard'} />
        ));
      });
  };

  const [summarizePost, { loading: isSummarizingPost }] = useSummarizePost();
  const getSummarizePost = () => {
    summarizePost({
      variables: {
        where: {
          id: { equals: postId },
          project: {
            id: { equals: projectId },
          },
        },
        orderBy: { createdAt: 'desc' },
      },
      onCompleted: (data: any) => {
        setSummarizePostData(data?.summarizePostThread);
      },
      onError: (error: any) => {
        toast((t) => <ToastMessage id={t.id} title={`${error.message}`} />);
      },
    });
  };

  const [showImageModal, setShowImageModal] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const contentRef2 = useRef<HTMLDivElement>(null);

  const {
    control,
    watch,
    handleSubmit,
    handleCreateComment,
    handleEditorChange,
    isCreatingComment,
    editorConfig,
    initializeCommentEditor,
    handleMentionButtonClick,
    handleEmojiClick,
  } = useComment({
    postId: postId as string,
    collaborators: projectData?.project?.collaborators,
    editorPlaceholder: 'Add a comment',
  });

  const formValues = watch();

  if (loading || loadingProject)
    return <PageLoader snippet="Loading posts details and comments" />;

  const userCollaborates = userExistAsCollaborator(
    userId,
    projectData?.project
  );

  return (
    <Fragment>
      <SectionWrap className="h-auto w-full relative flex items-center flex-col">
        <div className="rounded-[8px_8px_0_0] w-full flex items-start gap-4 px-6 py-4 sticky top-0 z-10 bg-[inherit] border-b border-[var(--border-divider)]">
          <IconButton
            icon={<ArrowBack />}
            size={'standard'}
            type={'ghost'}
            onClick={() => navigate(-1)}
          />
          <HeaderText
            subTitle="Comments"
            snippet={`${post?.comments?.length} comments`}
          />
        </div>

        <div
          className={cn(
            'w-full items-center flex flex-col h-full pb-[50px] overflow-auto'
          )}
        >
          <div className="py-6 flex flex-col items-start gap-6 w-full max-w-[54%]">
            <div className="flex items-center space-x-6 w-full">
              <div className="flex-1 flex space-x-2 items-center">
                <Avatar
                  name={post?.author.detail?.fullName ?? post?.author.email}
                  src={post?.author.detail.avatar?.url}
                  loading={loading}
                  alt={`${post?.author.detail?.fullName} pfp`}
                  size={32}
                />
                <div className="w-max flex items-center gap-x-2">
                  <div className="text-body-small medium text-[var(--text-default)] capitalize">
                    {post?.author.detail?.fullName}
                  </div>
                  <Timestamp
                    className="text-[var(--text-neutral)] text-body-micro regular"
                    createdAt={post?.createdAt}
                  />
                </div>
              </div>

              <div className="flex gap-6 items-center">
                <ul className="flex gap-2 items-start">
                  {post?.topic && (
                    <li>
                      <ColorTag
                        value={post?.topic.name}
                        size="medium"
                        type="filled"
                        leftIcon={<WorkStream />}
                        useDefaultColor={post?.topic.name === 'All'}
                      />
                    </li>
                  )}
                  {post?.type && (
                    <li>
                      <StatusTag
                        value={transformToTitleCase(post.type)}
                        size="medium"
                        type="filled"
                        color="default"
                        leftIcon={<MegaPhone className="w-3 h-3" />}
                      />
                    </li>
                  )}
                </ul>

                <PostMenu
                  handleSummarize={() => getSummarizePost()}
                  handleCopy={handleCopyPost(post)}
                  canEdit={isPostOwner}
                  handleEdit={() =>
                    setState({
                      ...state,
                      editPost: { show: true, post },
                    })
                  }
                  canDelete={isPostOwner}
                  handleDelete={() =>
                    setState({
                      ...state,
                      deletePost: { show: true, post },
                    })
                  }
                />
              </div>
            </div>

            <div className="space-y-2 w-full">
              <h2 className="text-heading-6 medium text-[var(--text-default)]">
                {post?.title}
              </h2>

              <div className="w-full relative">
                <article
                  ref={contentRef2}
                  className={`ck ck-content text-[var(--text-default)]`}
                >
                  {parse(post?.comment ?? '')}
                </article>
              </div>
            </div>

            {post?.attachments.length > 0 && (
              <ul className="flex space-x-2 w-full">
                {post?.attachments.map(
                  (
                    media: { url: any; filename: string | undefined },
                    key: number
                  ) => (
                    <li
                      key={key}
                      className={cn(
                        'aspect-square flex-1 max-h-[300px] overflow-hidden rounded-lg',
                        post.attachments.length < 2 ? '!max-w-[50%]' : ''
                      )}
                    >
                      <img
                        onClick={() => {
                          setShowImageModal(true);
                          setCurrentImage(key);
                        }}
                        className="aspect-square bg-grey-100 rounded-lg object-cover bg-[red]"
                        src={media.url}
                        alt={media.filename}
                      />
                    </li>
                  )
                )}
              </ul>
            )}

            <ImageViewer
              images={post?.attachments}
              currentImage={currentImage}
              setCurrentImage={(e: number) => setCurrentImage(e)}
              showImageModal={showImageModal}
              setShowImageModal={() => setShowImageModal(false)}
            />
          </div>
          <HorizontalDivider />

          {isSummarizingPost || summarizePostData !== null ? (
            <AnimatePresence>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
                layoutId="summarize-post"
                className="w-full py-5 max-w-[54%] flex items-start flex-col gap-y-4"
              >
                <div className="w-full flex justify-between items-center">
                  <div className="flex items-center gap-x-2.5">
                    <AiLogo />
                    <small className="text-body-small font-medium bg-gradient-summary inline-block text-transparent bg-clip-text">
                      {isSummarizingPost ? 'Summarizing' : 'Summarized'}
                    </small>
                  </div>

                  {!isSummarizingPost && summarizePostData !== null && (
                    <IconButton
                      type="ghost"
                      size="micro"
                      onClick={() => {
                        setSummarizePostData(null);
                      }}
                      icon={<Close />}
                    />
                  )}
                </div>

                {summarizePostData !== null && (
                  <Fragment>
                    <div className="w-full border border-[var(--border-on-bg)] bg-[var(--backgrounds-default)] p-4 rounded-[12px]">
                      <div className="text-body-small text-[var(--text-on-bg)] font-medium">
                        {renderLineBreaks(summarizePostData)}
                      </div>
                    </div>

                    {/*  <div className="w-full flex items-center gap-x-2.5 justify-center">
                   <IconButton
                        type="ghost"
                        size="micro"
                        rounded="micro"
                        onClick={() => {}}
                        icon={<ThumbsUp />}
                        className="!h-5 !w-5 outline"
                      />
                      <IconButton
                        type="ghost"
                        size="micro"
                        onClick={() => {}}
                        icon={<ThumbsDown />}
                        className="!h-5 !w-5 outline"
                      />
                    </div> */}
                  </Fragment>
                )}

                {isSummarizingPost && (
                  <div className="w-full flex flex-col items-start gap-y-1">
                    <div
                      className="h-5 w-[176px] rounded-lg animate-pulse"
                      style={{
                        background: 'var(--skeleton-bg)',
                      }}
                    />
                    <div
                      className="h-5 w-full rounded-lg animate-pulse"
                      style={{
                        background: 'var(--skeleton-bg)',
                      }}
                    />
                    <div
                      className="h-5 w-full rounded-lg animate-pulse"
                      style={{
                        background: 'var(--skeleton-bg)',
                      }}
                    />
                  </div>
                )}
              </motion.div>
              <HorizontalDivider />
            </AnimatePresence>
          ) : null}

          <PostComments
            comments={post?.comments}
            renderComment={(comment) => (
              <PostCommentItem
                myData={user}
                comment={comment}
                userCollaborates={userCollaborates}
                collaborators={projectData?.project?.collaborators}
                postId={postId as string}
              />
            )}
          />
        </div>

        <div className="py-4 px-4 sticky bottom-0 z-10 w-full bg-[inherit] border-t border-[var(--border-divider)] rounded-[0_0_8px_8px]">
          <CommentInputField
            showUserAvatar
            user={user as IUser}
            name="comment"
            control={control}
            disabled={!userCollaborates}
            onReady={initializeCommentEditor}
            handleEditorChange={handleEditorChange}
            editorConfig={editorConfig}
            loadingBtn={isCreatingComment}
            disabledBtn={isCreatingComment || !formValues.comment}
            onMentionClick={handleMentionButtonClick}
            onEmojiClick={handleEmojiClick}
            onClick={handleSubmit((data) => handleCreateComment(data))}
            btnLabel={'Comment'}
          />
        </div>
      </SectionWrap>

      <PostModal
        initialPost={state.editPost?.post}
        show={Boolean(state.editPost?.show)}
        handleClose={() =>
          setState({
            ...state,
            editPost: {
              show: false,
              post: undefined,
            },
          })
        }
        collaborators={projectData?.project?.collaborators}
      />

      <DeletePostModal
        post={state.deletePost?.post}
        show={Boolean(state.deletePost?.show)}
        handleClose={() =>
          setState({
            ...state,
            deletePost: {
              show: false,
              post: undefined,
            },
          })
        }
      />
    </Fragment>
  );
}
