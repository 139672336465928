import { Menu, Transition } from '@headlessui/react';
import { Fragment, ReactNode } from 'react';
import { Archive, Copy, Edit, ThreeDots, Trash } from 'src/assets/icons';
import { ReactComponent as AiLogo } from 'src/assets/svgs/ImgAISummary.svg';
import { IProject, IWorkspaceMember, UserWorkspaceRole } from 'src/types';
import SectionWrap from '../common/SectionWrap';
import IconButton from '../ui/Button/IconButton';
import { DropItem } from '../ui/DropGroup';
import HorizontalDivider from '../ui/HorizontalDivider';

// #don't think this component is meant to exist - refactor that menu instead

function ProjectPageHeader({
  project,
  handleCatchUp,
  handleEdit,
  handleDelete,
  handleArchive,
  handleCopyLink,
  userId,
  workspaceMembers,
  children,
}: {
  userId: string | null | undefined;
  project: IProject;
  children: ReactNode;
  handleCatchUp: () => void;
  handleEdit: Function;
  handleArchive: Function;
  handleDelete: Function;
  handleCopyLink: Function;
  workspaceMembers: IWorkspaceMember[] | null;
}) {
  const isProjectOwner = userId === project.owner.id;

  const isWorkspaceOwner =
    workspaceMembers?.find(
      (member: IWorkspaceMember) => member.user.id === userId
    )?.isOwner ?? false;

  const isWorkspaceAdmin =
    workspaceMembers?.find(
      (member: IWorkspaceMember) => member.user.id === userId
    )?.role !== UserWorkspaceRole.MEMBER;

  const canEdit = isProjectOwner || isWorkspaceOwner || isWorkspaceAdmin;

  return (
    <SectionWrap className="!rounded-[8px_8px_0_0]">
      <div className="px-6 pt-4 pb-6 flex flex-col gap-6 items-start w-full">
        <div className="flex items-center justify-between w-full">
          <h1 className="text-heading-3 bold text-[var(--text-default)]">
            {project.name} 
          </h1>
          <Menu as="div" className="relative inline-block text-left">
            {({ open }) => (
              <>
                <div>
                  <Menu.Button>
                    <IconButton
                      open={open}
                      size="micro"
                      type="ghost"
                      rounded="small"
                      icon={<ThreeDots />}
                    />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="drop-group absolute z-10 right-0 mt-2 w-48 origin-top-right">
                    <div className="px-1 py-1 ">
                      <Menu.Item>
                        {({ active }) => (
                          <div>
                            <button>
                              <DropItem
                                item={{
                                  name: 'Catch-up',
                                  icon: <AiLogo />,
                                }}
                                className="bg-gradient-summary inline-block !text-transparent !bg-clip-text"
                                onItemClick={handleCatchUp}
                              />
                            </button>
                            <HorizontalDivider className="my-1 w-full" />
                          </div>
                        )}
                      </Menu.Item>
                      {canEdit && (
                        <Menu.Item>
                          {({ active }) => (
                            <button>
                              <DropItem
                                item={{
                                  name: `Edit`,
                                  icon: <Edit />,
                                }}
                                onItemClick={() => handleEdit()}
                              />
                            </button>
                          )}
                        </Menu.Item>
                      )}
                      <Menu.Item>
                        {({ active }) => (
                          <button>
                            <DropItem
                              item={{
                                name: 'Copy Link',
                                icon: <Copy />,
                              }}
                              onItemClick={() => handleCopyLink()}
                            />
                          </button>
                        )}
                      </Menu.Item>
                      {canEdit && (
                        <>
                          <Menu.Item>
                            {({ active }) => (
                              <button>
                                <DropItem
                                  item={{
                                    name: `Archive`,
                                    icon: <Archive />,
                                  }}
                                  onItemClick={() => handleArchive()}
                                />
                              </button>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <button>
                                <DropItem
                                  item={{
                                    name: `Delete`,
                                    icon: <Trash />,
                                  }}
                                  onItemClick={() => handleDelete(handleDelete)}
                                />
                              </button>
                            )}
                          </Menu.Item>
                        </>
                      )}
                    </div>
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        </div>
        {children}
      </div>
    </SectionWrap>
  );
}

export default ProjectPageHeader;
