import { IProject } from "src/types";
import { IWorkspace } from "src/types";

export const PROJECT_ACCESS_LEVELS = [
  'ANYONE_WITH_LINK',
  'INVITE_ONLY',
  'WORKSPACE_ONLY'
] as const;

export type ProjectAccessLevel = (typeof PROJECT_ACCESS_LEVELS)[number]

export type IProjectAccessLevelChecker = (project: IProject) => ProjectAccessLevel;

export const isLockedAccessLevel = (level: ProjectAccessLevel) => level === 'INVITE_ONLY';

export type HumanReadableAccessLevelsMap = {
  [name in ProjectAccessLevel]: string
}

export const humanReadableAccessLevels: HumanReadableAccessLevelsMap = {
  'ANYONE_WITH_LINK': 'Anyone with the link',
  'INVITE_ONLY': 'Only people invited',
  'WORKSPACE_ONLY': 'Everyone at workspace_name'
}

export const readableProjectAccessLevel = (access: ProjectAccessLevel, workspaceName: IWorkspace['name']) => {
    const humanReadable = humanReadableAccessLevels[access];

    if (access === 'WORKSPACE_ONLY') {
        return humanReadable.replace('workspace_name', workspaceName);
    } else {
        return humanReadable
    }
}

export const checkProjectAccess: IProjectAccessLevelChecker = (project) => {
    if (project.isGeneralPublic && project.isPublic) return 'ANYONE_WITH_LINK';
    if (project.isPublic) return 'WORKSPACE_ONLY';
    return 'INVITE_ONLY'; // Default case
};